<template>
	<figure v-lazyload class="image__wrapper">
		<ImageSpinner class="image__spinner" />
		<img :alt="alt" :data-url="source" />
	</figure>
</template>

<script>
import ImageSpinner from "./ImageSpinner";

export default {
	name: "ImageItem",
	components: {
		ImageSpinner,
	},
	props: {
		source: {
			type: String,
			required: true,
		},
		alt: {
			type: String,
			required: true,
		},
	},
};
</script>

<style scoped lang="scss">
.image {
	&__wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		@media (max-width: 980px) {
			width: 12rem;
			height: 5.4rem;
		}
		@media (max-width: 480px) {
			width: 10rem;
			height: 3.4rem;
		}
		&.loaded {
			.image {
				&__item {
					visibility: visible;
					opacity: 1;
					border: 0;
				}
				&__spinner {
					display: none;
					width: 100%;
				}
			}
		}
	}

	&__item {
		width: 100%;
		border-radius: 4px;
		transition: all 0.4s ease-in-out;
		opacity: 0;
		visibility: hidden;
	}
}
</style>
